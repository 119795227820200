import { useState, useRef } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Card,
  CardContent,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Link,
  Stack,
  useTheme,
  ButtonGroup,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEllipsisV, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { components } from 'openapi-types';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useDeleteSiteTemplate, useGetSiteTemplates } from 'api/site';
import { formatDate } from 'utils/dateFormat';
import { useNavigate } from 'react-router-dom';
import { linkHelper } from 'linkHelper';

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

export type SiteTemplatesPerPage = 10 | 50 | 100;
export interface SiteTemplatesPaginationState {
  perPage: SiteTemplatesPerPage;
  activePageNumber: number;
  filter: string;
  sortAttr: 'name' | 'created_at' | 'updated_at';
  sortOrder: 'asc' | 'desc';
}

type SiteTemplateRowProps = {
  readonly template: NonNullable<
    NonNullable<components['schemas']['ListSiteTemplatesResponse']['result']>['site_templates']
  >[0];
};

function TemplateRow({ template }: SiteTemplateRowProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const { mutateAsync: deleteTemplate } = useDeleteSiteTemplate();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <TableRow key={template.template_id}>
      <TableCell>{template.name}</TableCell>
      <TableCell>
        {template.created_at
          ? formatDate({
              date: new Date(template.created_at).toISOString(),
            })
          : '-'}
      </TableCell>
      <TableCell>{template.created_from?.domain ?? '-'}</TableCell>
      <TableCell>{template.config?.wordpress?.version ?? '-'}</TableCell>
      <TableCell
        sx={{
          textAlign: 'center',
        }}
      >
        <ButtonGroup>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              navigate(
                linkHelper.sites.create.getLink({
                  templateId: template.template_id ?? '',
                })
              );
            }}
          >
            {t('create_site')}
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              navigate(
                linkHelper.sites.viewTemplate.getLink({
                  templateId: template.template_id ?? '',
                })
              );
            }}
          >
            {t('view')}
          </Button>
          <Button
            ref={anchorRef}
            variant="text"
            color="primary"
            sx={{
              minHeight: '2.75rem',
              width: '30px',
            }}
            fullWidth
            onClick={async () => {
              setOpenTableMenu(true);
            }}
          >
            <FontAwesomeIcon size="lg" icon={faEllipsisV} onClick={() => {}} />
            <ConfirmationDialog
              action="delete"
              onConfirm={async () => {
                deleteTemplate(template.template_id ?? '', {
                  onSuccess: () => {
                    setOpenConfirmationDialog(false);
                  },
                });
              }}
              description={t('delete_description', {
                name: template.name ?? template.template_id,
              })}
              onClose={() => setOpenConfirmationDialog(false)}
              open={openConfirmationDialog}
              title={t('delete_template')}
            />
            <Popper
              popperOptions={{
                placement: 'bottom-start',
              }}
              open={openTableMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpenTableMenu(false)}>
                      <MenuList autoFocusItem={openTableMenu} id="site-templates-list">
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            setOpenTableMenu(false);
                            setOpenConfirmationDialog(true);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faTrash} />{' '}
                            <Box>{t('delete_template')}</Box>
                          </Stack>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
}

export function SiteTemplatesList() {
  const { t } = useTranslation();
  const { data, status } = useGetSiteTemplates();
  const templates = (data?.data.result?.site_templates ?? []).map(template => template);

  const [pagination, setPagination] = useState<SiteTemplatesPaginationState>({
    perPage: 10 as SiteTemplatesPerPage,
    activePageNumber: 1,
    filter: '',
    sortAttr: 'name',
    sortOrder: 'asc',
  });
  const opositeSortOrder = pagination.sortOrder === 'asc' ? 'desc' : 'asc';

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowSkeleton />;
    }

    return templates.map(template => {
      if (!template) {
        return null;
      }
      return <TemplateRow key={template.template_id ?? ''} template={template} />;
    });
  };

  return (
    <Card sx={{ marginTop: '0px !important' }}>
      <CardContent>
        <TableContainer>
          <Table aria-label="Site Templates List Table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box component="span">
                    <Link
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          sortAttr: 'name',
                          sortOrder: pagination.sortAttr === 'name' ? opositeSortOrder : 'asc',
                        });
                      }}
                    >
                      {t('template_name')}{' '}
                      {pagination.sortAttr === 'name' ? (
                        <Box display="inline-block" marginLeft={1}>
                          <FontAwesomeIcon
                            icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                          />
                        </Box>
                      ) : null}
                    </Link>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box component="span">
                    <Link
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          sortAttr: 'created_at',
                          sortOrder:
                            pagination.sortAttr === 'created_at' ? opositeSortOrder : 'asc',
                        });
                      }}
                    >
                      {t('created')}
                      {pagination.sortAttr === 'created_at' ? (
                        <Box display="inline-block" marginLeft={1}>
                          <FontAwesomeIcon
                            icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                          />
                        </Box>
                      ) : null}
                    </Link>
                  </Box>
                </TableCell>
                <TableCell>{t('created_from')}</TableCell>
                <TableCell>{t('wp_version')}</TableCell>
                <TableCell width="190px">{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
